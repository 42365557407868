import React, { useState, useEffect } from "react";
import { Card, Table, Tabs, Tooltip, Pagination } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { ethers } from 'ethers';

const PAGE_SIZE = 10; // Number of transactions per page

function RecentActivity({ history, requests }) {
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedHistory, setPaginatedHistory] = useState([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    setPaginatedHistory(history.slice(startIndex, endIndex));
  }, [currentPage, history]);

  const toggleExpand = (record) => {
    setExpandedRows(prev => ({
      ...prev,
      [record.key]: !prev[record.key]
    }));
  };

  const renderExpandIcon = (record) => (
    <span onClick={() => toggleExpand(record)} style={{ cursor: 'pointer', marginRight: '8px' }}>
      {expandedRows[record.key] ? <DownOutlined /> : <RightOutlined />}
    </span>
  );

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const historyColumns = [
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text) => {
        const color = text === 'Send' ? 'red' : 'green';
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `$${ethers.utils.formatUnits(amount, 6)}`,
    },
    {
      title: 'Date & Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => formatDate(timestamp),
    },
    {
      title: 'Details',
      dataIndex: 'message',
      key: 'message',
      render: (message, record) => {
        const fullMessage = `${message}\n\nSender: ${record.otherPartyName || record.otherPartyAddress}\n\nFee: $${ethers.utils.formatUnits(record.fee, 6)}\n\nDate: ${formatDate(record.timestamp)}`;
        return (
          <div>
            <Tooltip title={fullMessage} overlayStyle={{ whiteSpace: 'pre-line' }}>
              <span>{`${message.slice(0, 15)}${message.length > 15 ? '...' : '' }`}</span>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const requestColumns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${ethers.utils.formatUnits(amount, 6)} USDC`,
    },
    {
      title: 'Details',
      dataIndex: 'message',
      key: 'message',
      render: (message, record) => {
        const from = record.name || `${record.requestor.slice(0, 6)}...${record.requestor.slice(-4)}`;
        const fullMessage = `${message}\n\nFull details:\nFrom: ${from}`;
        return (
          <div>
            <Tooltip title={fullMessage} overlayStyle={{ whiteSpace: 'pre-line' }}>
              <span>{`${message.slice(0, 20)}${message.length > 20 ? '...' : ''} - ${from}`}</span>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const historyData = paginatedHistory.map((item, index) => ({
    key: `history-${index}`,
    ...item,
  }));

  const requestData = requests.incoming.map((request, index) => ({
    key: `request-${index}`,
    ...request,
  }));

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const items = [
    {
      key: '1',
      label: 'Payment History',
      children: historyData.length > 0 ? (
        <div>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table 
              dataSource={historyData} 
              columns={historyColumns} 
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <p style={{ margin: 0 }}>
                    Sender: {record.otherPartyName || record.otherPartyAddress}<br />
                    Transaction Fee: ${ethers.utils.formatUnits(record.fee, 6)}<br />
                    Date: {formatDate(record.timestamp)}
                  </p>
                ),
                expandedRowKeys: Object.keys(expandedRows).filter(key => expandedRows[key]),
                onExpand: (expanded, record) => toggleExpand(record),
              }}
            />
          </div>
          <Pagination
            current={currentPage}
            total={history.length}
            pageSize={PAGE_SIZE}
            onChange={handlePageChange}
            style={{ marginTop: '20px', textAlign: 'right' }}
          />
        </div>
      ) : (
        <p>No payment history</p>
      ),
    },
    {
      key: '2',
      label: 'Requests',
      children: requestData.length > 0 ? (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Table 
            dataSource={requestData} 
            columns={requestColumns} 
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{ margin: 0 }}>
                  Full Message: {record.message}<br />
                  From: {record.name || record.requestor}
                </p>
              ),
              expandedRowKeys: Object.keys(expandedRows).filter(key => expandedRows[key]),
              onExpand: (expanded, record) => toggleExpand(record),
            }}
          />
        </div>
      ) : (
        <p>No pending requests</p>
      ),
    },
  ];

  return (
    <Card title="Recent Activity" style={{ width: "100%", minHeight: "672px" }}>
      <Tabs items={items} />
    </Card>
  );
}

export default RecentActivity;