import React, { useState } from "react";
import { Layout, Button, Typography, Steps } from "antd";
import { UserOutlined, WalletOutlined, DollarOutlined, SwapOutlined, HistoryOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { Step } = Steps;

function Help() {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Wallet',
      content: (
        <>
          <Title level={4}>You'll need a Base compatible wallet to use this dApp.</Title>
          <Paragraph>
            Access through your favorite wallet's explorer, or use on a desktop with wallet extension:
          </Paragraph>
          <ol>
            <li>Visit the <a href="https://opencrypt.io/" target="_blank" rel="noopener noreferrer">Opencrypt website</a> for more information.</li>
            
          </ol>
        </>
      ),
      icon: <UserOutlined />,
    },
    {
      title: 'Connect',
      content: (
        <>
          <Title level={4}>Connect Wallet</Title>
          <Paragraph className="text-[16px]">
            To connect your wallet to the application:
          </Paragraph>
          <ol className="text-[14px] text-[#7a7a7a] flex flex-col gap-3">
            <li>Click on the "Connect Wallet" button on the website.</li><hr/>
            <li>Select a wallet from the list of available wallet options.</li><hr/>
            <li>Approve the connection request in the popup.</li><hr/>
            <li>Your wallet is now connected and ready to use with the application.</li>
          </ol>
        </>
      ),
      icon: <WalletOutlined />,
    },
    {
      title: 'Pay a Request',
      content: (
        <>
          <Title level={4}>Pay for Requests</Title>
          <Paragraph className="text-[16px]">
            To make a payment:
          </Paragraph>
          <ol className="text-[14px] text-[#7a7a7a] flex flex-col gap-3">
            <li>Click on the "Pay" button in the application.</li><hr/>
            <li>Select the request you want to pay.</li><hr/>
            <li>Review the transaction details and confirm the payment.</li><hr/>
            <li>Approve the transaction in your wallet.</li><hr/>
            <li>Wait for the transaction to be confirmed on the blockchain.</li>
          </ol>
        </>
      ),
      icon: <DollarOutlined />,
    },
    {
      title: 'Make a Request',
      content: (
        <>
          <Title level={4}>Request Payments</Title>
          <Paragraph className="text-[16px]">
            To request a payment:
          </Paragraph>
          <ol className="text-[14px] text-[#7a7a7a] flex flex-col gap-3">
            <li>Click on the "Request" button in the application.</li><hr/>
            <li>Enter the address of the person you're requesting payment from and the amount.</li><hr/>
            <li>Add a message or description for the payment request.</li><hr/>
            <li>Confirm the request details and submit it.</li><hr/>
            <li>The request will be sent to the specified address.</li>
          </ol>
        </>
      ),
      icon: <SwapOutlined />,
    },
    {
      title: 'Recent Activity',
      content: (
        <>
          <Title level={4}>Recent Activity</Title>
          <Paragraph className="text-[16px]">
            To view your recent activity:
          </Paragraph>
          <ol className="text-[14px] text-[#7a7a7a] flex flex-col gap-3">
            <li>Look for the "Recent Activity" or "Transaction History" section in the application.</li><hr/>
            <li>Here you can see a list of your recent transactions, including payments made, payments received, and pending requests.</li><hr/>
            <li>Click on individual transactions to view more details.</li><hr/>
            <li>Use this section to keep track of your payment history and manage your finances.</li>
          </ol>
        </>
      ),
      icon: <HistoryOutlined />,
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", display: "flex", justifyContent: "center", backgroundColor: "white" }}>
      <Content className="max-w-4xl w-full mx-auto p-8">
        <Title level={2} className="mb-8 text-left">How It Works</Title>
        <Steps current={current} className="mb-8">
          {steps.map(item => (
            <Step key={item.title} title={item.title} icon={item.icon} />
          ))}
        </Steps>
        <div className="steps-content p-6 bg-white rounded-lg shadow-none">
          {steps[current].content}
        </div>
        <div className="steps-action mt-8 flex justify-between">
          {current > 0 && (
            <Button onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => setCurrent(0)}>
              Restart Tutorial
            </Button>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default Help;