import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import { Layout, Button, message, Modal, Popover } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import CurrentBalance from "./componets/CurrentBalance";
import RequestAndPay from "./componets/RequestAndPay";
import AccountDetails from "./componets/AccountDetails";
import RecentActivity from "./componets/RecentActivity";
import AdminDashboard from "./componets/AdminDashboard";
import { useConnect, useAccount, useDisconnect, useContractRead, useBalance } from "wagmi";
import { readContract } from '@wagmi/core'
import About from "./componets/About";
import Help from "./componets/Help";
import { ethers } from 'ethers';
import ABI from "./abi.json";
import LandingPage from "./componets/LandingPage";
import NotFoundPage from './componets/404';

const { Header, Content } = Layout;

const contractAddr = "0x7CaeB00713ff428C8D2bbf9837eB63FBe0074497";
const usdcTokenAddress = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";

function App() {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect({
    onSuccess: () => {
      message.success("Wallet connected successfully!");
      setIsWalletModalOpen(false);
    },
    onError: (error) => {
      message.error(`Connection failed: ${error.message}`);
    },
  });

  const [balance, setBalance] = useState("...");
  const [usdcBalance, setUsdcBalance] = useState("...");
  const [history, setHistory] = useState([]);
  const [requests, setRequests] = useState({ incoming: [], outgoing: [] });
  const [transactionFeePercentage, setTransactionFeePercentage] = useState(0);
  const [isOwner, setIsOwner] = useState(false);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  const { data: historyData, error: historyError } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'getMyHistory',
    args: [address],
    enabled: Boolean(address),
  });

  const { data: feePercentageData, error: feePercentageError } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'transactionFeePercentage',
    enabled: Boolean(address),
  });

  const { data: balanceData } = useBalance({
    address: address,
    enabled: Boolean(address),
  });

  const { data: usdcBalanceData } = useBalance({
    address: address,
    token: usdcTokenAddress,
    enabled: Boolean(address),
  });

  const { data: ownerData } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'owner',
  });

  const getRequests = useCallback(async () => {
    if (address) {
      try {
        const data = await readContract({
          address: contractAddr,
          abi: ABI,
          functionName: 'getMyRequests',
          args: [address],
        });
        console.log("Raw data from getMyRequests:", data);
        
        setRequests({
          incoming: data.incomingRequests,
          outgoing: data.outgoingRequests
        });
        
        console.log("Processed requests:", requests);
      } catch (error) {
        console.error("Error fetching requests:", error);
        message.error("Failed to fetch requests");
      }
    }
  }, [address]);
  
  useEffect(() => {
    getRequests();
    const interval = setInterval(getRequests, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, [getRequests]);

  useEffect(() => {
    setIsOwner(ownerData === address);
  }, [ownerData, address]);

  useEffect(() => {
    if (historyData) {
      setHistory(historyData);
    }
    if (historyError && isConnected) {
      console.error("Error fetching history:", historyError);
      message.error("Failed to fetch transaction history");
    }
  }, [historyData, historyError, isConnected]);

  useEffect(() => {
    if (feePercentageData) {
      setTransactionFeePercentage(Number(feePercentageData));
    }
    if (feePercentageError && isConnected) {
      console.error("Error fetching fee percentage:", feePercentageError);
      message.error("Failed to fetch transaction fee percentage");
    }
  }, [feePercentageData, feePercentageError, isConnected]);

  useEffect(() => {
    if (balanceData) {
      setBalance(ethers.utils.formatEther(balanceData.value));
    }
  }, [balanceData]);

  useEffect(() => {
    if (usdcBalanceData) {
      setUsdcBalance(ethers.utils.formatUnits(usdcBalanceData.value, 6)); // USDC has 6 decimals
    }
  }, [usdcBalanceData]);

  const disconnectAndSetNull = useCallback(() => {
    disconnect();
    setBalance("...");
    setUsdcBalance("...");
    setHistory([]);
    setRequests({ incoming: [], outgoing: [] });
    setIsOwner(false);
  }, [disconnect]);

  const handleConnectWallet = () => {
    setIsWalletModalOpen(true);
  };

  const handleLandingPageAction = () => {
    if (isConnected) {
      // If connected, navigate to the main application page
      // You might want to use React Router's navigate function here
      // For now, we'll just reload the page
      window.location.reload();
    } else {
      // If not connected, open the wallet modal
      setIsWalletModalOpen(true);
    }
  };

  const helpContent = (
    <div>
      <p>Connect your wallet to get started</p>
      <ul className="">
        <li>Use <b>"Pay"</b> to handle incoming requests.</li>
        <li>Use <b>"Request"</b> to ask for payments.</li><hr/>
        <li >Set your username for easier identification</li>
      </ul>
    </div>
  );

  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Header className="flex header-main bg-white justify-center items-center w-full h-[120px] z-50">
            <div className="flex flex-row w-full justify-between gap-2 items-center px-0 md:px-8">
              <div className="header-left flex ">
                <Link to="/"><img src="./volxelogo.png" alt="Volxe Logo" className="w-[100%] ml-[-5px] md:ml-[0px] h-[auto] md:w-[200px] "/></Link>      
              </div>
              <div className="header-right flex w-auto items-center">
                {isConnected ? (
                  <>
                    {isOwner && (
                      <Button
                        type="primary"
                        onClick={() => setIsAdminModalOpen(true)}
                        className="font-[500] text-[14px] p-[20px] flex items-center mr-2"
                      >
                        Admin
                      </Button>
                    )}
                    <Button
                      type="primary"
                      onClick={disconnectAndSetNull}
                      className="font-[800] text-[14px] bg-[black!important] hover:bg-[gray!important] p-[20px] flex items-center"
                    >
                      Disconnect
                    </Button>
                  </>
                ) : (
                  <Button
                    type="primary"
                    onClick={handleConnectWallet}
                    className="font-[600] text-[15px] bg-[black!important] hover:bg-[gray!important] p-[20px] flex items-center"
                  >
                    Connect Wallet
                  </Button>
                )}
                <Popover content={helpContent} title="How to use Volxe">
                  <QuestionCircleOutlined style={{ fontSize: '24px', marginLeft: '10px', cursor: 'pointer' }} />
                </Popover>
              </div>
            </div>
          </Header>

          <Content className="content p-4 md:p-8">
            <Routes>
              <Route path="/about-us" element={<About />} />
              <Route path="/help" element={<Help />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route
                path="/"
                element={
                  isConnected ? (
                    <div className="w-full justify-center flex flex-col md:flex-row gap-5">
                      <div className="flex flex-col w-full md:w-[450px]">
                        <CurrentBalance usdcBalance={usdcBalance} />
                        <RequestAndPay
                          requests={requests}
                          address={address}
                          transactionFeePercentage={transactionFeePercentage}
                          getRequests={getRequests}
                        />
                        <AccountDetails address={address} balance={balance} usdcBalance={usdcBalance} />
                      </div>
                      <div className="w-full md:w-[50%]">
                        <RecentActivity history={history} requests={requests} />
                      </div>
                    </div>
                  ) : (
                    <LandingPage onConnect={handleLandingPageAction} />
                  )
                }
              />
            </Routes>
          </Content>
        </Layout>
      </BrowserRouter>
      <AdminDashboard 
        isOpen={isAdminModalOpen} 
        onClose={() => setIsAdminModalOpen(false)} 
        isOwner={isOwner}
      />
      <Modal
        title="Connect Wallet"
        open={isWalletModalOpen}
        onCancel={() => setIsWalletModalOpen(false)}
        footer={null}
        className="wallet-modal"
      >
        <div className="wallet-options">
          {connectors.map((connector) => (
            <Button
              key={connector.id}
              onClick={() => connect({ connector })}
              disabled={!connector.ready}
              className="wallet-option-button"
              loading={isLoading && connector.id === pendingConnector?.id}
            >
              {connector.name}
              {!connector.ready && " (unsupported)"}
            </Button>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default App;
