import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";

function CurrentBalance({usdcBalance}) {
  const formattedBalance = parseFloat(usdcBalance).toFixed(2);
  return (
    <Card title="Wallet Activity" className="">
      <div className="currentBalance" style={{ display: "flex", alignItems:"center" }}>
        <div style={{ fontSize: "25px" }}>$ {formattedBalance} USDC</div>
        <div style={{ fontSize: "20px" }}>Available</div>
      </div>
      <div className="balanceOptions">
        <p className="extraOption hover:bg-[gray!important] border-[lightgray!important] text-[gray!important] hover:text-[white!important] cursor-[none!important]">Buy</p>
        <p className="extraOption hover:bg-[gray!important] border-[lightgray!important] text-[gray!important] hover:text-[white!important] cursor-[none!important]">Liquidate</p>
      </div>
    </Card>
  );
}

export default CurrentBalance;