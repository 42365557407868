import React, { useState, useEffect } from 'react';
import { Modal, Button, InputNumber, message, Input, Space, Card, Row, Col, Typography, Statistic } from 'antd';
import { useContractRead, useContractWrite, usePrepareContractWrite, useAccount, useBalance } from 'wagmi';
import { ethers } from 'ethers';
import ABI from "../abi.json";

const { Title, Text } = Typography;

const contractAddr = "0x7CaeB00713ff428C8D2bbf9837eB63FBe0074497";
const usdcTokenAddress = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";

function AdminDashboard({ isOpen, onClose }) {
  const [newFeePercentage, setNewFeePercentage] = useState(0);
  const [newOwnerAddress, setNewOwnerAddress] = useState('');
  const { address } = useAccount();

  const { data: ownerData } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'owner',
  });

  const isOwner = ownerData === address;

  const { data: currentFeePercentage } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'transactionFeePercentage',
  });

  const { data: isPaused } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'paused',
  });

  const { data: contractBalance } = useBalance({
    address: contractAddr,
    token: usdcTokenAddress,
  });

  const { config: setFeeConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'setTransactionFeePercentage',
    args: [newFeePercentage],
    enabled: isOwner && newFeePercentage > 0,
  });

  const { write: setFee } = useContractWrite(setFeeConfig);

  const { config: withdrawFeesConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'withdrawFees',
    enabled: isOwner && contractBalance && contractBalance.value > 0,
  });

  const { write: withdrawFees } = useContractWrite(withdrawFeesConfig);

  const { config: pauseConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'pause',
    enabled: isOwner && !isPaused,
  });

  const { write: pause } = useContractWrite(pauseConfig);

  const { config: unpauseConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'unpause',
    enabled: isOwner && isPaused,
  });

  const { write: unpause } = useContractWrite(unpauseConfig);

  const { config: transferOwnershipConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'transferOwnership',
    args: [newOwnerAddress],
    enabled: isOwner && ethers.utils.isAddress(newOwnerAddress),
  });

  const { write: transferOwnership } = useContractWrite(transferOwnershipConfig);

  const handleSetFee = async () => {
    try {
      await setFee?.();
      message.success('Transaction fee percentage update initiated.');
    } catch (error) {
      console.error('Error setting fee:', error);
      message.error('Failed to update transaction fee percentage');
    }
  };

  const handleWithdrawFees = async () => {
    try {
      await withdrawFees?.();
      message.success('Fees withdrawal initiated.');
    } catch (error) {
      console.error('Error withdrawing fees:', error);
      message.error('Failed to withdraw fees');
    }
  };

  const handlePause = async () => {
    try {
      await pause?.();
      message.success('Contract pause initiated.');
    } catch (error) {
      console.error('Error pausing contract:', error);
      message.error('Failed to pause contract');
    }
  };

  const handleUnpause = async () => {
    try {
      await unpause?.();
      message.success('Contract unpause initiated.');
    } catch (error) {
      console.error('Error unpausing contract:', error);
      message.error('Failed to unpause contract');
    }
  };

  const handleTransferOwnership = async () => {
    try {
      await transferOwnership?.();
      message.success('Ownership transfer initiated.');
    } catch (error) {
      console.error('Error transferring ownership:', error);
      message.error('Failed to transfer ownership');
    }
  };

  if (!isOwner) {
    return null;
  }

  return (
    <Modal
    className='hidden md:block'
      title={<Title level={3}>Admin Dashboard</Title>}
      open={isOpen}
      onCancel={onClose}
      width={1000}
      footer={null}
      bodyStyle={{ background: '#ffffff', padding: '24px' }}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Row gutter={16}>
          <Col span={12}>
            <Card hoverable>
              <Statistic
                title="Current Fee Percentage"
                value={currentFeePercentage ? ethers.utils.formatUnits(currentFeePercentage, 2) : 'Loading...'}
                suffix="%"
              />
              <Space direction="horizontal" style={{ marginTop: '20px' }}>
                <InputNumber
                  value={newFeePercentage}
                  onChange={setNewFeePercentage}
                  min={0}
                  max={500}
                  style={{ width: '100%' }}
                />
                <Button type="primary" onClick={handleSetFee} block>Set New Fee</Button>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card hoverable>
              <Statistic
                title="Contract Balance"
                value={contractBalance ? ethers.utils.formatUnits(contractBalance.value, 6) : 'Loading...'}
                suffix="USDC"
              />
              <Button 
                type="primary" 
                onClick={handleWithdrawFees} 
                disabled={!contractBalance || contractBalance.value.eq(0)}
                style={{ marginTop: '20px' }}
                block
              >
                Withdraw Fees
              </Button>
            </Card>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Card hoverable>
              <Statistic
                title="Contract Status"
                value={isPaused ? 'Paused' : 'Active'}
              />
              {isPaused ? (
                <Button type="primary" onClick={handleUnpause} style={{ marginTop: '20px' }} block>Unpause Contract</Button>
              ) : (
                <Button type="primary" danger onClick={handlePause} style={{ marginTop: '20px' }} block>Pause Contract</Button>
              )}
            </Card>
          </Col>
          <Col span={12}>
            <Card hoverable>
              <Title level={4}>Transfer Ownership</Title>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Input
                  placeholder="New owner address"
                  value={newOwnerAddress}
                  onChange={(e) => setNewOwnerAddress(e.target.value)}
                />
                <Button 
                  type="primary" 
                  onClick={handleTransferOwnership} 
                  disabled={!ethers.utils.isAddress(newOwnerAddress)}
                  block
                >
                  Transfer Ownership
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
}

export default AdminDashboard;