import React, { useState, useEffect } from "react";
import { DollarOutlined, SwapOutlined } from "@ant-design/icons";
import { Modal, Input, InputNumber, message, Button, List, Tabs } from "antd";
import { useContractWrite, usePrepareContractWrite, useContractRead, useAccount } from "wagmi";
import { ethers } from 'ethers';
import ABI from "../abi.json";

const contractAddr = "0x7CaeB00713ff428C8D2bbf9837eB63FBe0074497";
const usdcTokenAddress = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
const LARGE_TRANSACTION_THRESHOLD = 1000; // Define the threshold for large transactions (in USDC)

// Minimal USDC ABI for allowance and approve functions
const USDC_ABI = [
  {
    constant: true,
    inputs: [
      { name: "_owner", type: "address" },
      { name: "_spender", type: "address" }
    ],
    name: "allowance",
    outputs: [{ name: "remaining", type: "uint256" }],
    type: "function"
  },
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" }
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    type: "function"
  }
];

function RequestAndPay({ requests, address, transactionFeePercentage, getRequests }) {
  const [activeModal, setActiveModal] = useState(null);
  const [requestAmount, setRequestAmount] = useState(0);
  const [requestAddress, setRequestAddress] = useState("");
  const [confirmRequestAddress, setConfirmRequestAddress] = useState("");
  const [requestMessage, setRequestMessage] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [needsAllowance, setNeedsAllowance] = useState(false);

  const { address: userAddress } = useAccount();

  const showModal = (modalType) => setActiveModal(modalType);
  const hideModal = () => {
    setActiveModal(null);
    setRequestAmount(0);
    setRequestAddress("");
    setConfirmRequestAddress("");
    setRequestMessage("");
    setSelectedRequestId(null);
    setIsLoading(false);
    setNeedsAllowance(false);
  };

  const calculateFee = (amount) => {
    return (Number(amount) * transactionFeePercentage / 10000).toFixed(6);
  };

  const { config: payRequestConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'payRequest',
    args: selectedRequestId !== null ? [selectedRequestId] : undefined,
    enabled: selectedRequestId !== null && !needsAllowance,
  });

  const { write: payRequest, isError: isPayRequestError, error: payRequestError } = useContractWrite(payRequestConfig);

  const { config: approveConfig } = usePrepareContractWrite({
    address: usdcTokenAddress,
    abi: USDC_ABI,
    functionName: 'approve',
    args: [contractAddr, ethers.constants.MaxUint256],
    enabled: needsAllowance,
  });

  const { write: approveUSDC, isError: isApproveError, error: approveError } = useContractWrite(approveConfig);

  const { data: allowance } = useContractRead({
    address: usdcTokenAddress,
    abi: USDC_ABI,
    functionName: 'allowance',
    args: [userAddress, contractAddr],
    watch: true,
  });

  const handlePayRequest = async (requestIndex) => {
    const request = requests.incoming[requestIndex];
    const amount = ethers.utils.formatUnits(request.amount, 6);
    const totalAmount = parseFloat(amount) + parseFloat(calculateFee(amount));

    if (allowance && allowance.lt(ethers.utils.parseUnits(totalAmount.toString(), 6))) {
      setNeedsAllowance(true);
      message.info("You need to approve USDC spending before making the payment.");
      return;
    }
    
    if (parseFloat(amount) > LARGE_TRANSACTION_THRESHOLD) {
      Modal.confirm({
        title: 'Large Transaction Warning',
        content: `You are about to send ${amount} USDC. Are you sure you want to proceed with this large transaction?`,
        onOk: () => processPayment(requestIndex),
        onCancel: () => console.log('Large transaction cancelled'),
      });
    } else {
      processPayment(requestIndex);
    }
  };

  const processPayment = async (requestIndex) => {
    setSelectedRequestId(requestIndex);
    setIsLoading(true);

    try {
      await payRequest?.();
      message.success("Payment initiated. Please confirm the transaction in your wallet.");
      getRequests(); // Refresh the requests after payment
    } catch (error) {
      console.error("Payment failed:", error);
      message.error("Transaction failed. Please check your wallet and try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveUSDC = async () => {
    setIsLoading(true);
    try {
      await approveUSDC?.();
      message.success("USDC approval initiated. Please confirm the transaction in your wallet.");
      setNeedsAllowance(false);
    } catch (error) {
      console.error("USDC approval failed:", error);
      message.error("USDC approval failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const { config: createRequestConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'createRequest',
    args: [requestAddress, ethers.utils.parseUnits(requestAmount.toString(), 6), requestMessage],
    enabled: Boolean(requestAddress) && requestAmount > 0 && Boolean(requestMessage),
  });

  const { write: createRequest, isLoading: isCreateRequestLoading, isError: isCreateRequestError, error: createRequestError } = useContractWrite(createRequestConfig);

  const handleCreateRequest = async () => {
    if (!requestAmount || !requestAddress || !confirmRequestAddress || !requestMessage) {
      message.error("Please fill in all fields.");
      return;
    }

    if (!ethers.utils.isAddress(requestAddress)) {
      message.error("Invalid Ethereum address.");
      return;
    }

    if (requestAddress !== confirmRequestAddress) {
      message.error("Addresses do not match.");
      return;
    }

    if (requestAmount <= 0) {
      message.error("Amount must be greater than 0.");
      return;
    }

    if (requestAmount > LARGE_TRANSACTION_THRESHOLD) {
      Modal.confirm({
        title: 'Large Request Warning',
        content: `You are about to request ${requestAmount} USDC. Are you sure you want to proceed with this large request?`,
        onOk: () => processCreateRequest(),
        onCancel: () => console.log('Large request cancelled'),
      });
    } else {
      processCreateRequest();
    }
  };

  const processCreateRequest = async () => {
    setIsLoading(true);
    message.loading({ content: 'Creating request...', key: 'createRequest', duration: 0 });

    try {
      await createRequest?.();
      message.success({ content: 'Request created successfully!', key: 'createRequest', duration: 2 });
      hideModal();
      getRequests(); // Refresh the requests after creation
    } catch (error) {
      console.error("Error creating request:", error);
      message.error({ content: 'Failed to create request. Please try again.', key: 'createRequest', duration: 2 });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPayRequestError) {
      message.error(`Error paying request: ${payRequestError?.message}`);
    }
  }, [isPayRequestError, payRequestError]);

  useEffect(() => {
    if (isCreateRequestError) {
      message.error(`Error creating request: ${createRequestError?.message}`);
    }
  }, [isCreateRequestError, createRequestError]);

  useEffect(() => {
    if (isApproveError) {
      message.error(`Error approving USDC: ${approveError?.message}`);
    }
  }, [isApproveError, approveError]);

  const renderRequestsList = (requestsArray, isUserRequests) => (
    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
      <List
        itemLayout="horizontal"
        dataSource={requestsArray}
        renderItem={(item, index) => (
          <List.Item
            actions={
              !isUserRequests
                ? [
                    needsAllowance ? (
                      <Button
                        key="approve"
                        loading={isLoading}
                        onClick={handleApproveUSDC}
                        disabled={isLoading}
                      >
                        Approve USDC
                      </Button>
                    ) : (
                      <Button
                        key="pay"
                        loading={isLoading && selectedRequestId === index}
                        onClick={() => handlePayRequest(index)}
                        disabled={isLoading}
                      >
                        {isLoading && selectedRequestId === index ? "Processing..." : "Pay this request"}
                      </Button>
                    )
                  ]
                : null
            }
          >
            <List.Item.Meta
              title={`${isUserRequests ? 'Request to' : 'Sender'}: ${item.name || item.requestor}`}
              description={
                <>
                  <p className="text-[15px]">Message: {item.message}</p>
                  {!isUserRequests && <p className="text-[15px]">Transaction Fee: {calculateFee(ethers.utils.formatUnits(item.amount, 6))} USDC</p>}
                  <p className="text-[25px] text-[#134ac0]"> ${ethers.utils.formatUnits(item.amount, 6)}</p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <>
      <Modal
        title="Requests"
        open={activeModal === 'requests'}
        onCancel={hideModal}
        footer={null}
        width={800}
        bodyStyle={{ maxHeight: '600px', overflow: 'auto' }}
      >
        <Tabs
          items={[
            {
              key: '1',
              label: `Requests to Pay (${requests.incoming.length})`,
              children: renderRequestsList(requests.incoming, false)
            },
            {
              key: '2',
              label: `Your Requests (${requests.outgoing.length})`,
              children: renderRequestsList(requests.outgoing, true)
            }
          ]}
        />
      </Modal>

      <Modal
        title="Create a request via wallet address"
        open={activeModal === 'createRequest'}
        onOk={handleCreateRequest}
        onCancel={hideModal}
        okText="Create Request"
        cancelText="Cancel"
        confirmLoading={isLoading || isCreateRequestLoading}
      >
        <Input
          className="h-[50px]"
          placeholder="0x000000000000000000000000000000000"
          onChange={(e) => setRequestAddress(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <Input
          className="h-[50px]"
          placeholder="Confirm Address"
          onChange={(e) => setConfirmRequestAddress(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <InputNumber
          className="h-[50px]"
          placeholder="Amount (USDC)"
          onChange={(value) => setRequestAmount(value)}
          style={{ marginBottom: "10px", width: "100%" }}
        />
        <Input
          className="h-[50px]"
          placeholder="Message"
          onChange={(e) => setRequestMessage(e.target.value)}
        />
      </Modal>

      <div className="requestAndPay mt-5">
        <div className="quickOption quickrequest" onClick={() => showModal('requests')}>
          <DollarOutlined style={{ fontSize: "28px" }} />
          Pay
          {requests.incoming.length > 0 && (
            <div className="numReqs">{requests.incoming.length}</div>
          )}
        </div>
        <div className="quickOption" onClick={() => showModal('createRequest')}>
          <SwapOutlined style={{ fontSize: "28px" }} />
          Request
        </div>
      </div>
    </>
  );
}

export default RequestAndPay;