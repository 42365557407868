import React, { useState, useEffect } from "react";
import { Card, Input, Modal, message, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import base from "../base.png";
import usdc from "../usdc.png";
import { useContractWrite, usePrepareContractWrite, useContractRead, useAccount, useConnect, useDisconnect } from "wagmi";
import { ethers } from 'ethers';
import ABI from "../abi.json";

const contractAddr = "0x7CaeB00713ff428C8D2bbf9837eB63FBe0074497";

function AccountDetails({ address, balance, usdcBalance }) {
  const [visible, setVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const { connector, isConnected } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();

  const { data: nameData, error: nameError, refetch: refetchName } = useContractRead({
    address: contractAddr,
    abi: ABI,
    functionName: 'getMyName',
    args: [address],
    enabled: Boolean(address),
  });

  useEffect(() => {
    if (nameData) {
      console.log("Name data fetched:", nameData);
      setUserName(nameData.name || "");
    }
    if (nameError) {
      console.error("Error fetching name:", nameError);
    }
  }, [nameData, nameError]);

  const { config } = usePrepareContractWrite({
    address: contractAddr,
    abi: ABI,
    functionName: 'addName',
    args: [userName],
    enabled: Boolean(userName),
  });

  const { write: addName, isLoading: isAddNameLoading, isSuccess: isAddNameSuccess } = useContractWrite(config);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    setUserName(e.target.value);
  };
  
  const setName = async () => {
    setIsUpdatingName(true);
    try {
      await addName?.();
      message.success("Name update transaction sent");
      // Wait a bit for the transaction to be mined, then refetch the name
      setTimeout(() => {
        refetchName();
        setIsUpdatingName(false);
      }, 5000);
    } catch (error) {
      console.error("Error setting name:", error);
      message.error("Failed to update name");
      setIsUpdatingName(false);
    }
  };

  useEffect(() => {
    if (isAddNameSuccess) {
      setVisible(false);
    }
  }, [isAddNameSuccess]);

  const displayName = userName || "Set Username";

  const handleSwitchAccounts = async () => {
    try {
      await disconnect();
      // You might want to show a modal or some UI for the user to choose a new wallet here
      // For now, we'll just reconnect with the current connector
      await connect({ connector });
    } catch (error) {
      console.error("Error switching accounts:", error);
      message.error("Failed to switch accounts");
    }
  };

  return (
    <Card title="Account Details" style={{ width: "100%" }}>
      <div className="accountDetailRow">
        <UserOutlined style={{ color: "#767676", fontSize: "25px" }} />
        <div>
          <div className="accountDetailHead">{displayName}</div>
          <div className="accountDetailBody">
            Address: {address?.slice(0, 4)}...{address?.slice(38)}
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div className="accountDetailRow">
          <img src={base} alt="baseLogo" width={25} />
          <div>
            <div className="accountDetailHead"> BASE Ξ</div>
            <div className="accountDetailBody">{parseFloat(balance).toFixed(6)} ETH</div>
          </div>
        </div>

        <div className="accountDetailRow">
          <img src={usdc} alt="USDC Logo" width={25} />
          <div>
            <div className="accountDetailHead">USDC</div>
            <div className="accountDetailBody">${parseFloat(usdcBalance).toFixed(2)} </div>
          </div>
        </div>
      </div>

      <Modal
        title="Enter a Username"
        open={visible}
        onOk={setName}
        onCancel={handleCancel}
        confirmLoading={isUpdatingName}
      >
        <Input className="flex h-[90px] text-[#134ac0] font-mono text-[45px]" placeholder="1 - 10 letters" value={userName} onChange={handleChange} />
      </Modal>
      <div className="balanceOptions">
        <div className="extraOption" onClick={showModal}>
          {displayName === "Set Username" ? "Set Username" : "Change Username"}
        </div>
        <div className="extraOption" onClick={handleSwitchAccounts}>Switch Accounts</div>
      </div>
    </Card>
  );
}

export default AccountDetails;