import React from 'react';
import './LandingPage.css';
import { useAccount } from 'wagmi';

const FocusedOlsaContent = ({ onConnect }) => {
  const { isConnected } = useAccount();

  return (
    <div className="olsa-content">
      <section className="hero">
        <div className="beta-tag">
          <span className="star-icon">֎</span>
          beta-version : carbon
        </div>
        <h1>Request, pay, and liquidate without any added stress.</h1>
        <p>Easily request or pay anyone in USD via wallet address. Conveniently buy or sell USDC with Naira, Euro, Cedi, Rupee, and Shilling.</p>

        <button className="btn btn-primary btn-large hover:scale-105" onClick={onConnect}>
          {isConnected ? "Connect Wallet" : "Launch application"}
          <span className="arrow-icon">→</span>
        </button>

        {/* 
        <div className="trust-indicator">
          <div className="avatar-group">
            <div className="avatar flex justify-center items-center">€</div>
            <div className="avatar flex justify-center items-center">Ξ</div>
            <div className="avatar flex justify-center items-center">₦</div>
          </div>
        </div>
  */}

      </section>
    </div>
  );
};

export default FocusedOlsaContent;