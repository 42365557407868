import React from 'react';
import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const data = {
  id: 1,
  name: "Opencrypt Labs",
  designation: "Blockchain development company",
  email: "https://opencrypt.io",
  image: "https://opencrypt.io/wp-content/uploads/2024/08/Copy-of-Copy-of-Opencryptyy.png",
  details: "Centralized | Decentralized | AI",
  details_2 :"Nigeria | Ghana | South Africa | United States"
};

const teamMembers = [
  {
    name: "Dev0x",
    usn: "4SU20CS026",
    image: "https://opencrypt.io/wp-content/uploads/2024/08/Copy-of-Copy-of-Opencryptyy.png",
    address: "Ether",
    email: "deepdev@",
    phoneNumber: "911",
  }
];

const AboutUs = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: '20px', textAlign: 'center', minWidth: "100%" }}>
      <Title level={2} style={{ marginBottom: '40px' }}>About Us</Title>
      <Card style={{ width: '100%', maxWidth: 800, borderRadius: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px', background: 'black' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', '@media (min-width: 768px)': { flexDirection: 'row' } }}>
          <div style={{ flex: 1, marginBottom: '20px', '@media (min-width: 768px)': { marginBottom: 0, marginRight: '20px' } }}>
            <img src={data.image} alt={data.name} style={{ width: '100%', maxWidth: '160px', height: 'auto', borderRadius: '10px' }} />
          </div>
          <div style={{ flex: 2, textAlign: "center" }}>
            <Paragraph style={{ marginBottom: '8px', fontSize: '16px', color: 'white' }}>{data.designation}</Paragraph>
            <Paragraph style={{ marginBottom: '8px', fontSize: '16px', color: 'white' }}><b>site:</b> <Link to={"https://opencrypt.io"}>{data.email}</Link> </Paragraph>
          </div>
        </div>
      </Card>
    
    </div>
  );
};

export default AboutUs;